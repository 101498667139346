// This file contains the styles for the VIDEO
// It is imported in the _components.scss file

.fp-storytile {
  &__container {
    @include padding(56.25% 0 0 0);
    overflow: scroll;
    position: relative;

    iframe {
      @include size(100%, 100%);
      border: 0;
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  &__badge {
    @include space(padding, all, $spacer * 2);
    background-color: $color-primary;
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
  }
}
// Fix for iframe header
.fp-storytile__container {
  .strytl-indicator-container {
    font-size: 90% !important;
    left: 0 !important;
    padding-top: 0 !important;
    position: absolute !important;
    top: 0 !important;
    width: 100% !important;
    z-index: 9;
  }

  > iframe {
    margin-top: 31px;
  }
}
