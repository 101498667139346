// This file includes all general IMAGE used throughout the project
// This file is imported in the _basics.scss file
.fp-article__image {
  position: relative;
}

// Aspect Ratio
.ratio .fp-article__image,
.ratio.fp-article__image {
  display: block;
  height: auto;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
  }
  // Make the contents sit on top of the :before
  > img {
    @include pos-absolute();
    @include size(100%, 100%, $maxw: 100%);
    object-fit: cover;
    object-position: center center;
  }

  > amp-img {
    @include pos-absolute();
    @include size(100%, 100%, $maxw: 100%);
    object-fit: cover;
    object-position: center center;
  }
}

.ratio,
.ratio.fp-article__image {
  @include ratio(16, 9);
  @include ratio(9, 16);
  @include ratio(1, 1);
  @include ratio(21, 9);
  @include ratio(5, 4);
  @include ratio(4, 3);
  @include ratio(3, 2);
  @include ratio(3, 1);
}

.ratio {
  @include ratio-on-child-image(16, 9);
  @include ratio-on-child-image(9, 16);
  @include ratio-on-child-image(1, 1);
  @include ratio-on-child-image(21, 9);
  @include ratio-on-child-image(5, 4);
  @include ratio-on-child-image(4, 3);
  @include ratio-on-child-image(3, 2);
  @include ratio-on-child-image(3, 1);
}

// Handle Image Width in Manuscript grid
.fp-image {
  @include width-ver($manuscript-width);
}

.fp-figure {
  text-align: center;

  @media (max-width: $tablet) {
    @include margins(0 0 $spacer * 4 0);
  }

  img {
    max-height: 600px;
    max-width: 100%;
  }

  amp-img {
    max-height: 600px;
    max-width: 100%;
  }
}

.fp-image-info {
  @include space(margin, only-vertical, $spacer * 2 $spacer * 6);
  color: #8c8787;
  color: rgba(42, 40, 40, 0.5);
  font-size: $text-base-size;
  @media (max-width: 700px) {
    @include margins($spacer * 2 $spacer * 4 $spacer * 8 $spacer * 4);
  }
}

.key-visual-image-wrapper {
  .fp-figure {
    @include size(100%, 0, $maxw: 100%);
    @include padding(0 0 42.85% 0);
    background-color: transparent;
    border-bottom: 0;
    overflow: hidden;
    position: relative;

    img {
      @include size(100%, 100%);
      background-color: transparent;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
    }
  }
}

.fp-article-block {
  .key-visual-image-wrapper .fp-figure {
    @include padding(0 0 56.25% 0);
  }

  img {
    object-fit: contain;
  }
}

.hide {
  display: none;
}
