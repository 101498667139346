.fp-category-name-header svg path {
  fill: $color-primary;
}

.fp-category-name-header {
  border-bottom: 1px solid $color-primary;
  @media screen and (max-width: $mobile) {
    border-bottom: 0;
  }
}

.fp-category-name-header__exclusive a {
  color: $color-primary-darker;
}
