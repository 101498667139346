// This file contains all the MOST POPULAR TOPICS styles
// This file is imported into the _groups.scss file

.fp-most-popular-topics {
  position: relative;

  &::before {
    @include size(100%, 2px);
    background-color: $color-primary;
    content: '';
    display: block;
    @media screen and (max-width: $mobile) {
      content: none;
    }
  }

  &__container {
    @include space(margin,top, 10px);
    background-color: $color-primary;
    background-image: url('../images/most-popular-topics-image.png');
    background-position: 135% -70px;
    background-repeat: no-repeat;
    position: relative;

    @media (max-width: $tablet) {
      background-size: cover;
    }

    @media (max-width: $mobile) {
      background-image: url('../images/most-popular-topics-image-mobile.png');
      background-position: left 0 bottom 0;
      background-size: 100%;

    }
  }

  &__header {
    min-height: 178px;
    @media screen and (max-width: $mobile) {
      min-height: auto;
    }
  }

  &__text {
    @include padding(32px 16px);
    @include font($text-xxxl, $font-extrabold, normal, $family: $font-secondary);
    color: $white;

    @media (max-width: $mobile) {
      padding: 32px 16px 8px;
      text-align: center;
    }
  }

  &__list {
    @include flex(null, center, null);
    align-items: stretch;

    @media (max-width: $tablet) {
      @include flex(null, initial, null, $wrap: wrap);
    }
    @media (max-width: $mobile) {
      @include margins( 0 $spacer * 4);
      bottom: -20px;
      position: relative;
    }
  }

  &__list-item {
    background-color: $color-primary;
    flex: 1;

    > div {
      height: 100%;
    }
    @media (max-width: $tablet) {
      flex-basis: 50%;

      &:nth-child(-n + 2) {
        border-bottom: $border-white;
      }
    }
    @media (max-width: $mobile) {
      flex-basis: 100%;

      &:nth-child(-n + 2) {
        border-bottom: 0;
      }

      &:not(:last-child) {
        @include space(margin, bottom, 20px);
      }
    }

    &:nth-child(odd) {
      @media (max-width: $tablet) {
        a {
          border-right: $border-white;
        }
      }
      @media (max-width: $mobile) {
        a {
          border-right: 0;
        }
      }
    }

    &:hover {
      a {
        background-color: darken($color-primary, 5%);
      }
    }

    a {
      @include flex(center, center, null);
      @include padding($spacer * 5);
      @include transition(0.2s, ease);
      @include font($text-lg, $weight-bold, normal, $font-primary);
      border-right: $border-white;
      color: $white;
      height: 100%;
      line-height: $line-height-lg;
      text-align: center;
      @media (max-width: $tablet) {
        border: 0;
      }

      @media (max-width: $tablet) {
        @include font($text-md, null, null, $family: $font-primary);
        line-height: $line-height-md;
      }
    }

    &:last-child a {
      border-right: 0;
    }
  }
}
