// This File Contains Scss for the Article block body and it's elements

.fp-article-width {
  @include width-ver($manuscript-width);
}

// Article Elements

.fp-subheading {
  @include space(padding, only-vertical, $spacer * 6 $spacer * 4);

  h2.text-xxl--bold {
    @media (max-width: 700px) {
      font-size: $text-md;
    }
  }
}

.fp-article {
  &-body {
    .fp-embed-tweet {
      @include space(padding, bottom, $spacer * 8);
      @media (max-width: 700px) {
        @include space(padding, bottom, $spacer * 4);
        @include font($text-base-size, $font-regular, normal);
        line-height: $line-height-md;
      }
    }

    .fp-embed-facebook {
      @include space(padding, bottom, $spacer * 8);
      @media (max-width: 700px) {
        @include space(padding, bottom, $spacer * 4);
        @include font($text-base-size, $font-regular, normal);
        line-height: $line-height-md;
      }
    }

    .fp-paragraph {
      @include size(100%, null, $maxw: 600px);
      @include margins(0 auto);
    }

    .fp-embed-tweet {
      @include flex(center);
      @include space(padding, bottom, $spacer * 4);
    }

    .fp-embed-facebook {
      @include flex(center);
      @include space(padding, bottom, $spacer * 4);
    }

    .fp-image {
      @include space(margin, only-vertical, $spacer * 4 $spacer * 8);
    }

    .fp-article-list {
      @include space(padding, vertical, $spacer * 6);

      li {
        font-size: $text-md;
        line-height: $line-height-lg;
        @media (max-width: 700px) {
          @include space(padding, all, 0);
          @include font($text-base-size, null, null, $lh: $line-height-md);
        }
      }

      .list-content {
        @media (max-width: 700px) {
          @include space(margin, left, $spacer * 4);
          @include space(margin, right, 0);
        }
      }

      ::marker {
        color: $color-primary;
      }
    }
  }
}

.fp-article-body {
  .fp-video,
  .fp-image-info {
    @include size(90%, null, $maxw: 600px);
    margin: 0 auto;
  }

  .fp-image-info {
    @include space(margin, only-vertical, $spacer * 2 $spacer * 6);

    @media (max-width: 700px) {
      @include margins(0 $spacer * 4 $spacer * 8 $spacer * 4);
    }
  }
}

.fp-article-block__social-media {
  @include space(padding, only-vertical, $spacer * 2 $spacer * 4 );

  .fp-article-block__social-media--button {
    @include space(margin, right, $spacer * 4);
    display: inline-flex;

    > span {
      display: flex;
    }

    svg path {
      @include transition(0.2s, $expo);
    }

    &:hover {
      svg path {
        stroke: $color-primary;
      }
    }
  }
}
