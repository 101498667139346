// This file contains all the GALLERY LIST styles
// This file is imported into the _components.scss file

.fp-gallery-list {
  &__main-teaser {
    @include margins(0 0 $spacer * 8 0);

    .fp-article__image {
      overflow: initial;
    }
  }

  &__main-teaser {
    @media screen and (max-width: $mobile) {
      .fp-article__image {
        margin-left: calc(-100vw / 2 + (100vw - 32px) / 2);
        margin-right: calc(-100vw / 2 + (100vw - 32px) / 2);
        max-width: 100vw;
        width: 100vw;
      }
    }
  }

  &__teaser {
    @include margins(0 0 $spacer * 8 0);

    &:last-child {
      @include margins(0);
    }

    a {
      @include flex();
      @media (max-width: $tablet) {
        @include flex(null, null, column);
      }
    }

    .fp-article__image {
      @include size(100%, 225px, $maxw: 400px);
      @include space(margin, right, $spacer * 6);
      @include space(margin, bottom, 0);

      @media (max-width: $tablet) {
        @include size(100%, auto, $maxw: 100vw);
        @include space(margin, bottom, $spacer * 4);
      }
    }

    &--content {
      @include flex(null, center, null);
      flex: 1;
    }
  }

  &__content {
    @include padding($spacer * 6 0 0);
  }

  &__teaser {
    .fp-article__image {
      overflow: initial;
      position: relative;
    }
  }

  &__badge {
    @include font($text-base-size, $weight-extraBold, normal, $font-secondary);
    @include padding($spacer * 3 $spacer * 4 $spacer * 3 $spacer * 6);
    background-color: $color-secondary;
    bottom: $spacer * 2;
    color: $white;
    display: block;
    left: -$spacer * 2;
    line-height: $text-base-size;
    position: absolute;

    @media screen and (max-width: $mobile) {
      @include padding($spacer * 2 $spacer * 4 $spacer * 2 $spacer * 6);
      bottom: -$spacer * 2;
      font-size: $text-xs;
      left: 0;
    }
  }
}

.fp-gallery-list__teaser:nth-child(n + 2) {
  .fp-gallery-list__badge {
    @media screen and (max-width: $mobile) {
      left: -16px;
    }
  }
}

.gallery-image {
  font-family: $font-primary;

  .m-asset-image__options {
    @include padding($spacer * 2 0 $spacer * 4 0);
    line-height: $line-height-base;
  }

  picture {
    display: block;
    max-width: 100%;
    text-align: center;
    width: 100%;
  }

  img {
    max-height: 700px;
    max-width: 100%;
    width: auto;
  }
}

.gallery-image-caption, .gallery-image-source {
  font-family: $font-primary;
  color: #000000;
  font-size: 18px;
}
