.normal {
  .fp-internal-teaser {
    @include padding($spacer * 4 $spacer * 6 );
    @include margins( 0  auto $spacer * 8 auto);
    border: 1px solid $light-gray;
    max-width: 500px;
    text-align: center;

    @media screen and (max-width: $mobile) {
      @include margins( 0  16px $spacer * 8 16px);
    }

    a {
      color: $color-text;
    }

    &_header {
      @include space(padding, bottom, $spacer * 2);
      font-family: $font-secondary;
      font-weight: $weight-bold;
      text-transform: uppercase;
    }

    &_category {
      @include space(padding, only-vertical, $spacer * 4 $spacer * 2);
      color: $color-primary;
      font-family: $font-secondary;
      font-size: $text-xs;
      font-weight: $weight-bold;
      line-height: $line-height-xs;
      text-transform: uppercase;
    }

    &_title {
      font-size: $text-base-size;
      font-weight: $weight-bold;
      line-height: $line-height-base;
    }
  }

  &.wrap-around {
    .fp-internal-teaser {
      @include padding($spacer * 4 $spacer * 4 );
      //@include margins( 0  auto $spacer * 8 auto);

      @media only screen and (min-width: 1000px) {
        @include space(margin, all, initial );
        @include space(margin, right, $spacer * 6 );
        @include size(290px, null);
        float: left;
        margin-bottom: $spacer * 4;

      }

      // Specific for BMG - should be placed in client file
      border: 1px solid #dad8d8;
      text-align: center;


      a {
        color: $color-text;
      }


      &_category {
        @include space(padding, bottom, $spacer * 2);
        text-transform: uppercase;
      }
    }
  }
}







// Media for Desktop view



