//Paywall Design

.fp-paywall {
  display: inline-block;
  position: relative;
  width: 100%;

  &.hidden {
    display: none !important;
  }

  &-header {
    @include space(margin, bottom, $spacer * 4);
  }

  &_paragraph {
    @include space(padding, bottom, $spacer * 4);
    font-family: 'Muli', sans-serif;
    font-size: $text-base-size;
    line-height: $line-height-base;
  }
}



