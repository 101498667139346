.m-socialbar--article {
  @include space(padding, top, $spacer * 4);
  @include space(margin, only-vertical, $spacer * 4 $spacer * 8);
  border-top: 1px solid #eee;
  display: flex;

  @media(max-width: 700px) {
    @include margins(0 16px 24px 16px);
  }
}

.a-socialbutton__link {
  @include flex(center, center, null);
  @include size(30px, 30px);
  @include margins(0 $spacer * 4 0 0);
}
