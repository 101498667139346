.fp-most-popular-topics__list-item {
  background-color: #00516f;
}

.fp-most-popular-topics__list-item a:hover {
  background-color: #0080af;
}


.fp-most-popular-topics__container {
  background-color: $color-primary;
}
