.fp-paid-article__article::before,
.fp-paid-article__section-title::before {
  min-width: 28px;

  @media screen and (max-width: $mobile) {
    min-width: 26px;
  }
}

.fp-paid-article {
  &__article {
    &::before {
      background-color: transparent;
      background-image: url('../images/vs-img.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 24px;
      min-width: 24px;
      @media screen and (max-width: $mobile) {
        min-width: 20px;
      }
    }
  }

  &__section-title {
    &::before {
      background-color: transparent;
      background-image: url('../images/vs-img.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      @media screen and (max-width: $mobile) {
        height: 24px;
        min-width: 24px;
        width: auto;
      }
    }
  }
}

.fp-paid-article-indicator-r {
  &__article {
    &::after {
      @include space(margin, left, $spacer * 4);
      background-color: transparent;
      background-image: url('../images/vs-plus.svg');
      background-repeat: no-repeat;
    }
  }
}
