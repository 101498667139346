// This file includes all the List Style for the project
// It is imported in the _components.scss file

.fp-article-list {
  @include font(
    $text-md,
    $font-regular,
    normal,
    $family: $font-primary,
    $lh: 26px
  );
  @media (max-width: 700px) {
    font-size: $text-sm;
  }

  &_horizontal {
    @include flex();
  }

  &_length {
    &-1 {
      .fp-article {
        width: calc((100% - #{$spacer * 6}));

        &:not(:last-child) {
          @include space(margin, right, $spacer * 2);
        }
      }
    }

    &-2 {
      .fp-article {
        width: calc((100% - #{$spacer * 6}) / 2);

        &:not(:last-child) {
          @include space(margin, right, $spacer * 2);
        }
      }
    }

    &-3 {
      .fp-article {
        width: calc((100% - #{$spacer * 6}) / 3);

        &:not(:last-child) {
          @include space(margin, right, $spacer * 2);
        }
      }
    }

    &-4 {
      .fp-article {
        width: calc((100% - #{$spacer * 8}) / 4);

        &:not(:last-child) {
          @include space(margin, right, $spacer * 2);
        }
      }
    }

    &-5 {
      .fp-article {
        width: calc((100% - #{$spacer * 10}) / 5);

        &:not(:last-child) {
          @include space(margin, right, $spacer * 2);
        }
      }
    }

    &-6 {
      flex-wrap: wrap;

      .fp-article {
        width: calc((100% - #{$spacer * 10}) / 3);

        &:not(:nth-child(3n)) {
          @include space(margin, right, $spacer * 2);
        }

        &:nth-child(n + 4) {
          @include space(margin, top, $spacer * 2);
        }
      }
    }
  }

  &_no-excerpt {
    .fp-article {
      &__excerpt {
        display: none;
      }
    }
  }

  &_no-date {
    .fp-article {
      &__date {
        display: none;
      }
    }
  }

  .list-content {
    @include margins(0 10%);
  }

  ::marker {
    color: $color-primary-light-2;
  }

  ul {
    list-style: disc;
    list-style-position: inside;

    li {
      @include margins(0 0 $spacer * 3 0);
      @include margins(0 0 0 $spacer * 4);
      display: list-item;
      list-style-position: outside;

      &::before {
        display: none;
      }
    }
  }

  &.lower-latin ul {
    list-style: lower-latin;
    list-style-position: inside;

    li {
      @include padding(0 0 0 $spacer * 4);

      &::before {
        display: none;
      }
    }
  }

  &.lower-roman ul {
    list-style: lower-roman;
    list-style-position: inside;

    li {
      @include padding(0 0 0 $spacer * 4);

      &::before {
        display: none;
      }
    }
  }

  &.decimal ul {
    list-style: decimal;
    list-style-position: inside;

    li {
      @include padding(0 0 0 $spacer * 4);

      &::before {
        display: none;
      }
    }
  }
}

// Article List Versions
.fp-article-list-ver {
  &_no-img {
    .fp-article {
      &__image {
        display: none;
      }
    }
  }
}
