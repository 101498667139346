.fp-free-html {
  @include space(padding, vertical, $spacer * 6);
  @include margins($spacer * 6 auto);
}

.fp-article-block {
  .fp-free-html {
    @include margins($spacer * 6 auto);
    max-width: 600px;
  }
}
