// This is the style fo separate inner page components
.general-page {
  .key-visual-image-wrapper {
    .fp-figure {
      @extend .full-width-element;
    }
    @media (max-width: 700px) {
      .fp-image-info {
        margin: 0 0 32px;
      }
    }
  }
}

.inner-page__component {
  &.fp-title {
    //default is large
    @include space(margin, bottom, $spacer * 4);
    @include font($text-xl, $weight-bold, normal, $font-primary);
    color: $color-text;
    line-height: $line-height-xl;

    &.title-small {
      @include font($text-base-size, $weight-bold, normal, $font-secondary);
      @include space(margin, bottom, $spacer * 4);
      line-height: $line-height-base;
    }

    &.title-medium {
      @include space(margin, bottom, $spacer * 6);
      @include font($text-lg, $weight-bold, normal, $font-secondary);
      line-height: $line-height-lg;
    }
  }

  &.fp-label-group {
    @include space(margin, bottom, $spacer * 5);
    display: flex;

    .fp-label-group {
      &__label {
        @include font(
          $text-base-size,
          $weight-semiBold,
          normal,
          $font-secondary
        );
        color: $color-primary--50;
        line-height: $line-height-base;
        width: 195px;
        @media screen and (max-width: $mobile) {
          @include space(margin, bottom, $spacer * 2);
          width: 100%;
        }
      }

      &__value {
        @include space(margin, left, $spacer * 2);
        @include font(
          $text-base-size,
          $weight-semiBold,
          normal,
          $font-secondary
        );
        color: $color-text;
        line-height: $line-height-base;
        width: calc((100% - 195px) - 8px);
        @media screen and (max-width: $mobile) {
          @include space(margin, left, 0);
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $mobile) {
      flex-direction: column;
    }
  }

  &.fp-spacer {
    @include space(padding, only-vertical, $spacer * 8);
  }
}
