// Style for Sport Widgets
// FuPa Widget

// General Style for FuPa Widgets
.fupa_wrapper {
  @include space(padding, only-vertical, $spacer * 6 $spacer * 8);
  @include size(90%, null, $maxw: $container-max-width);
  @include margins(0 auto);
  overflow: scroll;
  @media (max-width: 700px) {
    @include size(95%, null, $maxw: $container-max-width);
  }
}

// FuPa Widget in Article
.fp-article-body {
  .fupa_wrapper {
    @include size(90%, null, $maxw: 600px);
    @include margins(0 auto);
    @media (max-width: 700px) {
      @include size(auto, null, $maxw: 600px);
      @include margins(0 $spacer * 4);
    }
  }
}

.fupa_widget * {
  font-family: $font-secondary;
}

#fupa_livebox * {
  font-family: $font-secondary;
}
