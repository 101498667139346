// This file contains all the HEADER MINI TEASERS styles
// This file is imported into the _components.scss file

.fp-mini-teasers {
  @include space(margin, bottom, $spacer * 4);

  .fp-grid-row {
    @include grid(1fr 1px 1fr 1px 1fr, null, null);

    @media(max-width: $tablet) {
      @include grid(1fr, 0, auto);
    }
  }

  hr {
    @include size(1px, 90px);
    @include margins(0);
    background: $color-primary--30;
    border: 0;
    outline: none;

    &:nth-of-type(3) {
      display: none;
    }

    @media(max-width: $tablet) {
      display: none;
    }
  }

  &__item {
    @include flex(flex-start, flex-start, column);

    &:hover {
      .fp-mini-teasers__title h3 {
        text-decoration: underline;
      }

      .fp-mini-teasers__image {
        img {
          @include scale(1.03);
        }
      }
    }

    @media(max-width: $tablet) {
      @include padding($spacer * 2 0 $spacer * 2 $spacer * 5);
      border-bottom: $border-light-gray;
    }

    .fp-video__badge {
      @include space(padding, all, $spacer);
      left: initial;
      right: 0;

      svg {
        height: auto;
        width: 10px;
      }

      @media screen and (max-width: $tablet) {
        height: 100%;
      }
    }

    &.fp-mini-teasers__no-img {
      .fp-mini-teasers__title h3 {
        margin: 0;
      }
    }
  }

  &__image {
    @include size($spacer * 15, $spacer * 15);
    overflow: hidden;

    img {
      @include responsive-image();
      @include transition(0.1s, ease);
    }

    @media(max-width: $tablet) {
      display: none;
    }
  }

  &__catchline {
    @include font($text-xs, $font-bold, normal, $family: $font-secondary);
    @include padding(0 0 $spacer * 2);
    color: $color-primary;
    text-transform: uppercase;

    @media(max-width: $tablet) {
      display: none;
    }
  }

  &__title {
    @include space(padding, right, $spacer);
    flex: 1;

    h3 {
      @include font($text-sm, $font-bold, normal, $lh: 1.3);
      @include elipsis(3);
      @include margins(0 $spacer 0 0);
      color: $color-text;
      @media(max-width: $tablet) {
        display: inline;
      }
    }

    @media(max-width: $tablet) {
      @include space(padding, right, 0);
      display: list-item;
      list-style-position: outside;

      &::marker {
        color: $color-text;
      }
    }
  }

  &__details {
    @include size(100%, 100%);
    @include flex();
    @include margins(0);
    flex: 1;
    position: relative;
  }
}

