// This file contains the styles for the VIDEO
// It is imported in the _components.scss file

.fp-video {
  &__container {
    @include padding(56.25% 0 0 0);
    overflow: hidden;
    position: relative;

    iframe {
      @include size(100%, 100%);
      border: 0;
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  &__badge {
    @include space(padding, all, $spacer * 2);
    background-color: $color-primary;
    bottom: 0;
    display: flex;
    left: 0;
    position: absolute;
  }
}
