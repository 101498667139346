@font-face {
  font-display: swap;
  font-family: 'Domine';
  font-weight: 400;
  src: url('/design/source/fonts/Domine-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Domine';
  font-weight: 500;
  src: url('/design/source/fonts/Domine-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Domine';
  font-weight: 600;
  src: url('/design/source/fonts/Domine-SemiBold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Domine';
  font-weight: 700;
  src: url('/design/source/fonts/Domine-Bold.ttf') format('truetype');
}

