// This file contains the styles for the REGISTER BANNER
// It is imported in the _components.scss file

.fp-register-banner {
  &__content {
    @include space(margin, bottom, $spacer * 2);
    @include space(padding, all, $spacer * 15);
    background: $gray;
    color: $white;
    font-family: $font-primary;
    font-weight: $weight-semiBold;
    text-align: center;

    // For the newsletter TODO Angel

    .paywall-secondary-txt p {
      font-family: $font-secondary;
      font-size: $text-sm;
      font-weight: $weight-regular;
      text-decoration: underline;
      @media (max-width: 700px) {
        font-size: $text-xs;
      }

      a {
        color: white;

        &:hover {
          font-weight: bold;
        }
      }
    }

    button {
      @include space(margin, bottom, $spacer * 2);
      @include padding($spacer * 4 $spacer * 15);
      background-color: white;
      border: 0;
      border-radius: 5px;
      color: $color-primary;
      display: inline-block;
      font-family: $font-secondary;
      font-size: $text-lg;
      font-weight: $weight-bold;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;

      @media (max-width: 700px) {
        font-size: $text-sm;
      }

      a {
        color: $color-primary;
      }
    }
  }

  &__logo-small {
    display: none;
  }

  &__title {
    @include space(padding, bottom, $spacer * 3);
    color: white;
    line-height: $line-height-xxl;
    @media (max-width: $tablet) {
      font-size: $text-lg;
      font-weight: $font-semibold;
    }
  }

  &__text {
    color: white;
    font-family: $font-secondary;
    font-size: $text-md;
    line-height: $line-height-md;
    @media (max-width: $tablet) {
      font-size: $text-base-size;
      line-height: $line-height-base;
    }
  }

  &__button {
    @include space(margin, top, $spacer * 8.5);
    @include padding($spacer * 4 $spacer * 6.5);
    @include transition(0.2s, $expo);
    background: $white;
    border: 0;
    color: $gray;
    cursor: pointer;
    display: inline-block;
    font-family: $font-primary;
    font-size: $text-lg;
    font-weight: $weight-semiBold;
    text-align: center;
    text-decoration: none;

    @media (max-width: 700px) {
      font-size: $text-md;
    }
  }

  &__icon {
    display: none;
  }
}
