//This component is used in paywall design

.fp-button-group {
  @include space(padding, top, $spacer * 2);
  align-items: center;
  display: flex;
  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }

  &_label {
    @include space(padding, right, $spacer * 4);
    font-weight: $weight-bold;
    line-height: $line-height-base;
    @media screen and (max-width: $tablet) {
      @include space(padding, right, 0);
      @include space(padding, bottom, $spacer * 4);
    }
  }

  &_button {
    @media screen and (max-width: $tablet) {
      width: 100%;
    }

    span {
      width: 100%;
    }
  }
}
