// This file contains all the NEWSLETTER BANNER styles
// This file is imported into the _components.scss file

.fp-newsletter-banner {
  &__wrapper {
    @include margins(0 0 $spacer * 16 0);
  }

  &__content {
    @include padding($spacer * 8 $spacer * 4);
    background: $gray;
    color: $white;
    font-family: $font-primary;
    font-weight: $weight-semiBold;
    text-align: center;
  }

  &__logo-container {
    @include flex(null, center, null);
    position: relative;

    &::before {
      @include size(100%, 1px);
      content: '';
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__logo {
    @include padding($spacer * 3 $spacer * 6);
    @include margins(0 auto);
    background-color: $white;
    position: relative;

    span {
      background-image: url($publication-newsletter-logo);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
    }
  }

  &__title {
    @include space(padding, bottom, $spacer * 4);
    color: $white;
    font-size: $text-xl;
    line-height: $line-height-xxl;
  }

  &__text {
    @include space(padding, bottom, $spacer * 4);
    color: $white;
    font-size: $spacer * 5;
    line-height: $line-height-xxl;
    @media (max-width: $tablet) {
      font-size: $text-lg;
      font-weight: $weight-regular;
      line-height: $line-height-lg;
    }
  }

  &__button {
    @include space(margin, bottom, $spacer * 2);
    @include size(100%, $spacer * 10, $maxw: $spacer * 100);
    background: $white;
    border: 0;
    border-radius: 5px;
    color: $gray;
    display: inline-block;
    font-family: $font-secondary;
    font-size: $spacer * 4;
    font-weight: $weight-bold;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    @media (max-width: 700px) {
      font-size: $text-sm;
    }

    a {
      color: $gray;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__link {
    font-family: $font-secondary;
    font-size: $text-sm;
    font-weight: $weight-regular;
    text-decoration: underline;
    @media (max-width: 700px) {
      font-size: $text-xs;
    }

    a {
      color: $white;

      &:hover {
        font-weight: $weight-bold;
      }
    }
  }
}
