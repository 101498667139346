// This file is a reference point for all the VARIABLES
// Each variable is commented and has basic instructions
// It is imported in the _basics.scss file

// Basic unit variables
$spacer: 4px;

// Transition variables
$expo: cubic-bezier(0.87, 0, 0.13, 1);

// Color variables
$primary: #198cff;

// Colors
$color-primary: #16417c;
$color-secondary: #2667c5;
$color-primary-light: #3e8df7;
$color-primary-light-2: #198cff;
$color-text: #2a2828;
$color-primary--50: #8aa0bd;
$color-primary--30: #b9c6d8;
$color-text--80: rgba(42, 40, 40, 0.8);
$color-borders: $color-primary--30;
$color-white: #fff;
$color-warning: #b80000;
$color-blue--50: #b9c6d8;
$inactive-link-color: #000;

$primary-color: $primary;

// Black, white, gray
$white: #fff;
$black: #000;

$dark-gray: #636363;
$gray: #9c9c9c;
$light-gray: #d0d0d0;
$lighter-gray: #f2f2f2;

// Border variables
$border-black: 1px solid $black;
$border-white: 1px solid $white;
$border-dark-gray: 1px solid $dark-gray;
$border-gray: 1px solid $gray;
$border-light-gray: 1px solid $light-gray;
$border-lighter-gray: 1px solid $lighter-gray;
$border-mz-blue: 2px solid $color-primary;

// Font weights
$font-extralight: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;

// Container variables
$container-max-width: 1000px;
$body-margin: 50px;
$body-negative-margin: -$body-margin;
$container-width: 100%;

// Article variables
$manuscript-width: 820px;

// Typography Variables
$font-primary: 'Domine', serif;
$font-secondary: 'Muli', sans-serif;

/* set base values */
$text-base-size: 16px;
$line-height-base: 22px;

// Font Size
$text-xs: 12px;
$text-sm: 14px;
$text-md: 18px;
$text-lg: 20px;
$text-xl: 22px;
$text-xxl: 24px;
$text-xxxl: 26px;

$text-teaser-index: 40px;

// Line Height
$line-height-xs: 18px;
$line-height-sm: 20px;
$line-height-md: 24px;
$line-height-lg: 27px;
$line-height-xl: 30px;
$line-height-xxl: 33px;
$line-height-xxxl: 36px;

// Font Weight
$weight-thin: 100;
$weight-extraLight: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semiBold: 600;
$weight-bold: 700;
$weight-extraBold: 800;
$weight-black: 900;

// Responsive variables
$mobile: 550px;
$tablet: 900px;

$publication-banner-logo-small-path: '/design/source/images/mz-plus.svg';
$publication-newsletter-logo: '/design/source/images/sao-logo.svg';
