// This file is imported into the _groups.scss.scss files

.secondary-teaser {
  @include space(padding, only-vertical, 24px);
  border-bottom: 1px solid $color-borders;

  &:first-child {
    @include space(padding, top, 0);
  }

  &:last-child {
    @include space(padding, bottom, 0);
    border-bottom: 0;
  }
  @media screen and (max-width: $mobile) {
    @include space(padding, bottom, 0);
    border-bottom: 0;
  }

  a {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 8px;
    grid-template-areas:
      'aside header '
      'aside content';
    grid-template-columns: 400px 1fr;
    width: 100%;
    @media (max-width: 1000px) {
      grid-template-columns: minmax(auto, 40%) 1fr;
    }
    @media screen and (max-width: $mobile) {
      grid-gap: 0;
      grid-template-areas:
        'aside'
        'header'
        'content';
      grid-template-columns: 1fr;
    }
  }

  &.fp-article__no-img a {
    grid-template-areas:
      'header header '
      'content content'
      'excerpt excerpt';
  }

  .fp-article {
    &__image {
      grid-area: aside;
      height: auto;
      max-width: 400px;
      width: auto;
      @media screen and (max-width: $mobile) {
        @include space(margin, bottom, $spacer * 2);
        max-width: 100%;
      }
    }

    &__catchline {
      @include padding(0);
      align-self: end;
      grid-area: header;
      @media screen and (max-width: $mobile) {
        @include space(padding, only-vertical, $spacer * 2);
        @include font($text-sm, $weight-semiBold, normal, $font-secondary);
        line-height: $line-height-sm;
      }
    }

    &__title {
      grid-area: content;
      @media screen and (max-width: $mobile) {
        @include font($text-md, $weight-bold, normal);
        line-height: $line-height-md;
      }
    }

    &__excerpt {
      grid-area: excerpt;
    }
  }
}



// Search result teasers Modification
.fp-search-results {
  .secondary-teaser {
    a {
      grid-template-areas:
        'aside header '
        'aside content'
        'aside date';
      grid-template-rows: min-content auto min-content;
      @media screen and (max-width: $mobile) {
        grid-gap: 0;
        grid-template-areas:
          'aside'
          'header'
          'content'
          'date';
      }
    }

    &.fp-article__no-img a {
      grid-template-areas:
        'header header '
        'content content'
        'date date';
    }

    &__date {
      grid-area: date;
    }
  }
}
