// This file contains all the BREAKING NEWS styles
// This file is imported into the _components.scss file


// Translation animation

@keyframes breaking-news {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.fp-breaking-news {
  @include margins(0 auto);
  @include space(margin, bottom, $spacer * 4);
  @include size(100%, $spacer * 9);
  @include flex();

  position: sticky;
  top: 0;
  transition: all 0.4s ease-in-out;
  z-index: 48;

  &:hover {
    .fp-breaking-news__news-reel {
      animation-play-state: paused;
    }
  }

  @media(max-width: $mobile) {
    @include size(null, auto);
    flex-direction: column;
    margin-left: -16px;
    margin-right: -16px;
    width: 100vw;
  }


  &__title {
    @include padding($spacer * 2 $spacer * 4);
    @include flex(center, center, null);
    background: $color-primary;
    color: $white;
    font-family: $font-secondary;
    font-size: $text-sm;
    font-weight: $weight-medium;
    text-transform: uppercase;
    white-space: nowrap;


    @media(max-width: $mobile) {
      @include padding($spacer * 2 $spacer * 6);
    }
  }

  &__news-container {
    @include size(100%, null);
    @include padding(0 $spacer * 4);
    @include flex(null, center, null);
    background-color: $color-warning;
    flex: 1;
    overflow: hidden;

    a {
      &:hover {
        color: white;
        text-decoration: underline;
      }
    }

    @media(max-width: $mobile) {
      @include padding($spacer * 2 $spacer * 4);
    }
  }

  &__news-reel {
    @include flex();
    animation: breaking-news;
    animation-duration: 30s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    flex: 1;
  }

  &__item {
    @include padding(0 $spacer * 4 0 0);

    &-title {
      color: $white;
      font-family: $font-secondary;
      font-size: $text-base-size;
      font-weight: $font-bold;
      white-space: nowrap;

      &::after {
        @include size($spacer , $spacer);
        @include margins(0 0 0 $spacer * 4);
        background: $white;
        border-radius: 100%;
        content: '';
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
