// This file contains all the ARTICLE TEASER styles
// This file is imported into the _components.scss file

.fp-article {
  &__content {
    @include flex(null, null, column);
    color: $black;
  }

  &__image {
    @include size(100%, 300px, $maxw: 100%);
    display: block;
    overflow: hidden;

    img {
      @include size(100%, 100%, inherit);
      object-fit: cover;
      object-position: center;
      transition: 0.2s ease;
    }
  }

  &__catchline {
    @include font(
      $text-base-size,
      null,
      normal,
      $family: $font-secondary,
      $lh: $line-height-base
    );
    @include padding($spacer * 4 0 $spacer * 2 0);
    color: $color-primary;
    text-transform: uppercase;
    @media (max-width: $tablet) {
      font-size: $text-sm;
    }
  }

  &__title {
    @include font(
      $text-xl,
      $weight-bold,
      normal,
      $family: $font-primary,
      $lh: $line-height-xl
    );
    color: $color-text;
    @media (max-width: $tablet) {
      @include font($text-base-size, null, null, $lh: $line-height-base);
    }
  }

  &__excerpt {
    @include space(margin, top, $spacer * 2);
    @include font($text-md, $weight-regular, normal);
    color: $color-text--80;
    line-height: $line-height-md;
  }

  &__date {
    @include font($text-base-size, $weight-regular, normal, $font-secondary);
    color: $color-primary--50;
    line-height: $line-height-base;
    @media screen and (max-width: $mobile) {
      @include space(padding, top , $spacer * 2);
    }

  }
}

//Article Component Versions

.fp-article-ver {
  //Article Version Without Image
  &_no-img {
    .fp-article {
      &__image {
        display: none;
      }
    }
  }
  //Article Version Image Bottom
  &_img-bottom {
    > a {
      display: flex;
      flex-direction: column;
    }

    .fp-article {
      &__image {
        @include space(margin, top);
        @include space(margin, bottom, initial);
        order: 1;
      }
    }
  }
  //Article Version Image Left
  &_img-left {
    .fp-article {
      > a {
        display: flex;
      }

      &__image {
        @include space(margin, right);
        @include space(margin, bottom, initial);
      }
    }
  }
  //Article Version Image Right
  &_img-right {
    .fp-article {
      > a {
        display: flex;
      }

      &__image {
        @include space(margin, left);
        @include space(margin, bottom, initial);
        order: 1;
      }
    }
  }
  //Article Version Image Width 1/3
  &_img-one-third {
    .fp-article {
      > a {
        display: flex;
      }

      &__image {
        @include space(margin, right);
        @include space(margin, bottom, initial);
        width: calc((100% - #{$spacer * 2}) / 3);
      }

      &__content {
        width: calc((100% / 3) * 2);
      }
    }
  }

  //Article Version Without Excerpt
  &_no-excerpt {
    .fp-article {
      &__excerpt {
        display: none;
      }
    }
  }
}
