// This file contains all the MAIN VERTICAL TEASERS styles
// This file is imported into the _components.scss file

.secondary-teaser-small {
  @include space(padding, only-vertical, 16px);
  border-bottom: 1px solid $color-borders;

  &:first-child {
    @include space(padding, top, 0);
  }

  &:last-child {
    @include space(padding, bottom, 0);
    border-bottom: 0;
  }
  @media (max-width: $mobile) {
    @include space(padding, bottom, 0);
    border-bottom: 0;
  }

  a {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 8px;
    grid-template-areas:
      'aside header '
      'aside content';
    grid-template-columns: 294px 1fr;
    grid-template-rows: max-content min-content;
    @media (max-width: $tablet) {
      grid-template-columns: minmax(auto, 30%) 1fr;
    }
    @media (max-width: $mobile) {
      grid-gap: 8px;
      grid-template-areas:
        'header header'
        'content aside';
      grid-template-columns: 1fr;
    }
  }

  // no key visual available
  &.fp-article__no-img a {
    grid-template-areas:
      'header header '
      'content content'
      'excerpt excerpt';

    @media (max-width: $mobile) {
      grid-gap: 8px;
      grid-template-areas:
        'header header'
        'content content';
      grid-template-columns: 1fr;
    }
  }

  &.fp-article__no-img {
    @media screen and (max-width: $mobile) {
      @include space(margin, bottom, 8px);
    }
  }

  .fp-article {
    &__image {
      grid-area: aside;
      max-width: 400px;
      width: auto;
      @media (max-width: $mobile) {
        height: 75px;
        width: 75px;
      }
    }

    &__catchline {
      @include padding(0);
      align-self: end;
      grid-area: header;
      @media (max-width: $mobile) {
        @include font($text-xs, $weight-semiBold, normal, $font-secondary);
        line-height: $line-height-xs;
      }
    }

    &__title {
      grid-area: content;
      @media (max-width: $mobile) {
        @include font($text-base-size, $weight-bold, normal);
        line-height: $line-height-base;
      }
    }

    &__excerpt {
      grid-area: excerpt;
      @media (max-width: $mobile) {
        display: none;
      }
    }
  }
}




// Search result teasers Modification
.fp-search-results {
  .secondary-teaser-small {
    a {
      grid-template-areas:
        'aside header '
        'aside content'
        'aside date';
      @media screen and (max-width: $mobile) {
        grid-template-areas:
          'header header'
          'content aside'
          'date aside';
      }
    }

    &.fp-article__no-img a {
      grid-template-areas:
        'header header '
        'content content'
        'date date';
    }

    &__date {
      grid-area: date;
    }
  }
}
