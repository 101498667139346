// Style for link component
.link-component {
  display: flex;
  flex-wrap: wrap;


  &_title {
    @include space(padding, bottom, $spacer * 2);
    color: #8aa0bd;
    display: block;
    font-size: $text-base-size;
    font-weight: $weight-semiBold;
    text-transform: uppercase;
  }

  li {
    @include space(padding, all,$spacer $spacer * 2);

    > a {
      align-items: center;
      color: $color-text;
      display: flex;
      font-family: $font-secondary;
      font-size: $text-base-size;
      font-weight: $weight-medium;

      &:hover {
        color: $color-primary;
      }
    }
  }

  ul .link-component_group:first-of-type {
    @include space(margin, top, $spacer);
  }
}

ul.link-component {
  > li {
    min-width: 200px;
    padding: 16px 0;
    width: 25%;

    @media screen and (max-width: $mobile) {
      width: 100%;
    }

    > a {
      @include space(padding, all, $spacer);
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
