// This file contains all the TYPOGRAPHY styles for the project
// It is imported in the _basics.scss file -design

//Typography Classes

.text-base {
  @include font($text-base-size, $weight-regular, normal);
  line-height: $line-height-base;

  &--bold {
    @include font($text-base-size, $weight-bold, normal);
    line-height: $line-height-base;
  }
  // FONT SECONDARY BASE
  &-secondary {
    @include font($text-base-size, $weight-regular, normal, $font-secondary);
    line-height: $line-height-base;

    &--bold {
      @include font($text-base-size, $weight-bold, normal, $font-secondary);
      line-height: $line-height-base;
    }

    &--semibold {
      @include font($text-base-size, $weight-semiBold, normal, $font-secondary);
    }

    &--extrabold {
      @include font($text-base-size, $weight-extraBold, normal, $font-secondary);
    }
  }
}

.text-xs {
  @include font($text-xs, $weight-regular, normal);
  line-height: $line-height-xs;

  &--bold {
    @include font($text-xs, $weight-bold, normal);
    line-height: $line-height-xs;
  }
  // FONT SECONDARY XS
  &-secondary {
    @include font($text-xs, $weight-regular, normal, $font-secondary);
    line-height: $line-height-xs;

    &--bold {
      @include font($text-xs, $weight-bold, normal, $font-secondary);
      line-height: $line-height-xs;
    }

    &--semibold {
      @include font($text-xs, $weight-semiBold, normal, $font-secondary);
      line-height: $line-height-xs;
    }
  }
}

.text-sm {
  @include font($text-sm, $weight-regular, normal);
  line-height: $line-height-sm;

  &--bold {
    @include font($text-sm, $weight-bold, normal);
    line-height: $line-height-sm;
  }

  &--semibold {
    @include font($text-sm, $weight-semiBold, normal);
    line-height: $line-height-sm;
  }
  // FONT SECONDARY SM
  &-secondary {
    @include font($text-sm, $weight-regular, normal, $font-secondary);
    line-height: $line-height-sm;

    &--bold {
      @include font($text-sm, $weight-bold, normal, $font-secondary);
      line-height: $line-height-sm;
    }

    &--semibold {
      @include font($text-sm, $weight-semiBold, normal, $font-secondary);
      line-height: $line-height-sm;
    }
  }
}

.text-md {
  @include font($text-md, $weight-regular, normal);
  line-height: $line-height-md;

  &--line-height-lg {
    @include font($text-md, $weight-regular, normal);
    line-height: $line-height-lg;
  }

  &--bold {
    @include font($text-md, $weight-bold, normal);
    line-height: $line-height-md;
  }

  // FONT SECONDARY MD
  &-secondary {
    @include font($text-md, $weight-regular, normal, $font-secondary);
    line-height: $line-height-md;

    &--bold {
      @include font($text-md, $weight-bold, normal, $font-secondary);
      line-height: $line-height-md;
    }

    &--semibold {
      @include font($text-md, $weight-semiBold, normal, $font-secondary);
      line-height: $line-height-md;
    }
  }
}

.text-lg {
  @include font($text-lg, $weight-regular, normal);
  line-height: $line-height-lg;

  &--bold {
    @include font($text-lg, $weight-bold, normal);
    line-height: $line-height-lg;
  }
  // FONT SECONDARY LG
  &-secondary {
    @include font($text-lg, $weight-regular, normal, $font-secondary);
    line-height: $line-height-lg;

    &--bold {
      @include font($text-lg, $weight-bold, normal, $font-secondary);
      line-height: $line-height-lg;
    }

    &--semibold {
      @include font($text-lg, $weight-semiBold, normal, $font-secondary);
      line-height: $line-height-lg;
    }
  }
}

.text-xl {
  @include font($text-xl, $weight-regular, normal);
  line-height: $line-height-xl;

  &--bold {
    @include font($text-xl, $weight-bold, normal);
    line-height: $line-height-xl;
  }
  // FONT SECONDARY XL
  &-secondary {
    @include font($text-xl, $weight-regular, normal, $font-secondary);
    line-height: $line-height-xl;

    &--bold {
      @include font($text-xl, $weight-bold, normal, $font-secondary);
      line-height: $line-height-xl;
    }

    &--semibold {
      @include font($text-xl, $weight-semiBold, normal, $font-secondary);
      line-height: $line-height-xl;
    }
  }
}

.text-xxl {
  @include font($text-xxl, $weight-regular, normal);
  line-height: $line-height-xxl;

  &--bold {
    @include font($text-xxl, $weight-bold, normal);
    line-height: $line-height-xxl;
  }
  // FONT SECONDARY XXL
  &-secondary {
    @include font($text-xxl, $weight-regular, normal, $font-secondary);
    line-height: $line-height-xxl;

    &--bold {
      @include font($text-xxl, $weight-bold, normal, $font-secondary);
      line-height: $line-height-xxl;
    }

    &--semibold {
      @include font($text-xxl, $weight-semiBold, normal, $font-secondary);
      line-height: $line-height-xxl;
    }
  }
}

.text-xxxl {
  @include font($text-xxxl, $weight-regular, normal);
  line-height: $line-height-xxxl;

  &--bold {
    @include font($text-xxxl, $weight-bold, normal);
    line-height: $line-height-xxxl;
  }
  // FONT SECONDARY XXXL
  &-secondary {
    @include font($text-xxxl, $weight-regular, normal, $font-secondary);
    line-height: $line-height-xxxl;

    &--bold {
      @include font($text-xxxl, $weight-bold, normal, $font-secondary);
      line-height: $line-height-xxxl;
    }

    &--semibold {
      @include font($text-xxxl, $weight-semiBold, normal, $font-secondary);
      line-height: $line-height-xxxl;
    }
  }
}

// Typography Mobile

.sm-text-sm {
  @media screen and (max-width: 700px) {
    @include font($text-sm, $weight-regular, normal);
    line-height: $text-sm;

    &--bold {
      @include font($text-sm, $weight-bold, normal);
      line-height: $text-sm;
    }
    // FONT SECONDARY SM
    &-secondary {
      @include font($text-sm, $weight-regular, normal, $font-secondary);
      line-height: $text-sm;

      &--bold {
        @include font($text-sm, $weight-bold, normal, $font-secondary);
        line-height: $text-sm;
      }

      &--semibold {
        @include font($text-sm, $weight-semiBold, normal, $font-secondary);
        line-height: $text-sm;
      }
    }
  }
}

.sm-text-md {
  @media screen and (max-width: 700px) {
    @include font($text-md, $weight-regular, normal);
    line-height: $text-md;

    &--bold {
      @include font($text-md, $weight-bold, normal);
      line-height: $text-md;
    }

    &-secondary {
      @include font($text-md, $weight-regular, normal, $font-secondary);
      line-height: $line-height-md;

      &--bold {
        @include font($text-md, $weight-bold, normal, $font-secondary);
        line-height: $line-height-md;
      }

      &--semibold {
        @include font($text-md, $weight-semiBold, normal, $font-secondary);
        line-height: $line-height-md;
      }
    }
  }
}

.sm-text-lg {
  @media screen and (max-width: 700px) {
    @include font($text-lg, $weight-regular, normal);
    line-height: $text-lg;

    &--bold {
      @include font($text-lg, $weight-bold, normal);
      line-height: $text-lg;
    }
  }
}
