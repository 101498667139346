// This file contains all the MOST POPULAR ARTICLES styles
// This file is imported into the _groups.scss file


.fp-most-popular-section {
  display: inline-block;
  width: 100%;
  @media screen and (max-width: $mobile) {
    margin-left: -16px;
    margin-right: -16px;
    width: 100vw;
  }
}

.fp-article-list {
  .fp-article-most-popular {
    @include size(null, null, $maxw: 620px);
    @include margins(0 auto);

    .fp-article {
      &__content {
        @include flex($dir: row);

        .fp-article-index {
          @include space(padding, top, $spacer * 2);
          @include font($text-teaser-index, null, null, $family: $font-primary);
          color: $color-primary;

          @media (max-width: $tablet) {
            @include space(padding, top, $spacer * 4);
            @include font($text-xxxl, null, null);
            font-size: $text-xxxl;
          }
        }

        header {
          @include padding(0 0 0 $spacer * 4);
        }
      }
    }

    &:not(:last-child) {
      @include padding(0 0 $spacer * 10 0);
      @media (max-width: $tablet) {
        @include padding(0 0 $spacer * 8 0);
      }
    }

    .fp-article__catchline {
      @include space(padding, top, 0);
    }
  }
}

.fp-most-popular-articles-teasers {
  @include space(margin, top, 15px);
  @include space(padding, vertical, 64px);
  border: 0.5px solid $color-primary--30;
  border-radius: $spacer;
  position: relative;

  @media (max-width: $tablet) {
    @include space(padding, vertical, 36px);
    border: initial;
    border-radius: 0;
    border-top: 1px solid $color-primary;

    &:last-child {
      @include space(padding, bottom, 0);
    }
  }

  @media screen and (max-width: $mobile) {
    padding-left: 16px;
    padding-right: 16px;
  }

  .fp-most-popular-section-title {
    @include space (padding, only-horizontal, $spacer * 2);
    @include font(
      $text-xxl,
      $weight-extraBold,
      normal,
      $family: $font-secondary
    );
    background-color: $color-white;
    color: $color-primary;
    left: $spacer * 4;
    position: absolute;
    top: -15px;


    @media (max-width: $tablet) {
      @include font($text-lg, $weight-bold, null);
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
