.fp-related-articles {
  #nativendo-nativendo-infeed {
    @include size(100%, null);
    @include margins($spacer * 6 0);

    @media(max-width: $mobile) {
      margin-bottom: 0;
    }
  }

  .fp-article-list {
    .fp-article {
      &:nth-of-type(4) {
        border-top: $border-light-gray;

        @media(max-width: $mobile) {
          border-top: 0;
        }
      }
    }
  }
}

.fp-most-popular-section {
  #nativendo-nativendo-infeed {
    @include size(100%, null);
    @include margins(0 0 $spacer * 10 0);

    @media(max-width: $tablet) {
      @include margins(0 0 $spacer * 8 0);
    }
  }
}

.fp-page-content {
  #nativendo-artikel {
    @include margins($spacer * 8 0);
  }
}



.fp-section-topic {
  #nativendo-nativendo-infeed {
    @include size(100%, null);
    @include margins($spacer * 6 0);
  }
}

.general-page {
  #nativendo-nativendo-infeed {
    @include size(100%, null);
    @include margins($spacer * 6 0);
  }
}
