// This file contains all the BUTTON styles
// This file is imported into the _components.scss file

.fp-button {
  @include padding($spacer * 2 $spacer * 4);
  @include transition(0.2s, $expo);
  @include font($text-sm, $font-bold, normal, $font-secondary);
  @include transition(0.2s, $expo);
  background: $color-primary;
  border: 0;
  border-radius: $spacer;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  min-width: 160px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  a {
    color: #fff;
  }

  &:hover {
    box-shadow: 0 $spacer $spacer * 2 rgba($black, 0.2);
    text-decoration: underline;
  }
}
