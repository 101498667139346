// This file contains all the CTA styles
// This file is imported into the _variant-vs.scss file

.fp-register-banner {
  &__content {
    @include padding($spacer * 8 $spacer * 4);
    background: #068;
  }

  &__logo-small {
    @include space(margin, vertical, 0);
    @include space(margin, horizontal, auto);
    @include space(margin, bottom, $spacer * 6);
    background-image: url($publication-banner-logo-small-path);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: $spacer * 15;
    width: 100%;
  }
}

.fp-register-banner__content button {
  @include transition(0.25s, ease);

  color: #068;
  font-size: $text-md;
  font-weight: $weight-bold;

  &:hover {
    background: $color-primary-darker;
    color: white;
  }
}

