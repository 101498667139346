// This file contains variant for the NEWSLETTER BANNER styles
// This file is imported into the _variant-vs.scss file

.fp-newsletter-banner {
  &__logo-container {
    &::before {
      background: $color-primary;
    }
  }

  &__logo {
    span {
      @include size(132px, 20px);
    }
  }

  &__content {
    background: $color-primary;
  }

  &__button {
    background: $color-primary-darker;
    color: $white;
  }
}

