
@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-weight: 900;
  src: url('/design/source/fonts/Muli-Black.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-weight: 800;
  src: url('/design/source/fonts/Muli-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-weight: 700;
  src: url('/design/source/fonts/Muli-Bold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-weight: 600;
  src: url('/design/source/fonts/Muli-SemiBold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-weight: 500;
  src: url('/design/source/fonts/Muli-Medium.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-weight: 400;
  src: url('/design/source/fonts/Muli-Regular.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-weight: 300;
  src: url('/design/source/fonts/Muli-Light.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-weight: 200;
  src: url('/design/source/fonts/Muli-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-style: italic;
  font-weight: 900;
  src: url('/design/source/fonts/Muli-BlackItalic.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-style: italic;
  font-weight: 800;
  src: url('/design/source/fonts/Muli-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-style: italic;
  font-weight: 700;
  src: url('/design/source/fonts/Muli-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-style: italic;
  font-weight: 600;
  src: url('/design/source/fonts/Muli-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-style: italic;
  font-weight: 500;
  src: url('/design/source/fonts/Muli-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-style: italic;
  font-weight: 400;
  src: url('/design/source/fonts/Muli-Italic.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Muli';
  font-style: italic;
  font-weight: 300;
  src: url('/design/source/fonts/Muli-LightItalic.ttf') format('truetype');
}
