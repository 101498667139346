.fp-paragraph {
  @include space(padding, bottom, $spacer * 8);
  @include font($text-md, $weight-regular, normal);
  line-height: $line-height-lg;

  @media (max-width: 700px) {
    @include space(padding, bottom, $spacer * 4);
    @include font($text-base-size, $font-regular, normal);
    line-height: $line-height-md;
  }

  a {
    color: $color-primary-light;

    &:hover {
      color: $color-primary;
    }
  }

  &.p {
    &-small {
      @include space(padding, bottom, $spacer * 4);
      @include font($text-base-size, $weight-semiBold, normal, $font-secondary);
      line-height: $line-height-base;
    }

    &-medium {
      @include space(padding, bottom, $spacer * 10);
      @include font($text-md, $weight-regular, normal, $font-secondary);
      line-height: $line-height-md;
    }

    &-big {
      @include space(padding, bottom, $spacer * 8);
      @include font($text-lg, $weight-regular, normal, $font-secondary);
      color: $color-text--80;
      line-height: $line-height-lg;
    }

    &-color-blue {
      color: $color-primary;
    }

    a {
      color: $color-primary-light;

      &:hover {
        color: $color-primary;
      }
    }
  }

  a[data-old-href] {
    color: $inactive-link-color;
  }

  a[data-old-href]:hover {
    color: $inactive-link-color;
  }
}
