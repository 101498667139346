.fp-paid-article {
  &__article,
  &__section-title {
    &::before {
      @include size(null, 28px, $minw: 38px);
      @include margins(0 $spacer 0 0);
      background-color: transparent;
      background-image: url('../images/editor-plus-badge.svg');
      content: '';
      display: inline-block;
      position: relative;
      top: $spacer;

      @media screen and (max-width: $mobile) {
        @include size(null, 18px, $minw: 28px);
      }
    }
  }

  &__section-title {
    &::before {
      top: 0;
    }
  }
}

.fp-paid-article {
  &__article {
    &::before {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 90%;
    }
  }

  &__section-title {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
  }
}

.fp-paid-article-indicator-r {
  &__article,
  &__section-title {
    &::after {
      @include size(null, 28px, $minw: 38px);
      @include margins(0 $spacer 0 0);
      background-color: transparent;
      background-image: url('../images/editor-plus-badge.svg');
      content: '';
      display: inline-block;
      position: relative;
      top: $spacer;
    }
  }
}

.fp-paid-article-indicator-r {
  &__article {
    &::after {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 90%;
    }
  }

  &__section-title {
    &::after {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 90%;
    }
  }
}
