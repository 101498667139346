// This file contains all the BLOCKQUOTE styles
// This file is imported into the _components.scss file

.fp-blockquote {
  @include space(padding, all, $spacer * 4);
  @include space(margin, only-vertical, $spacer * 4 $spacer * 8);
  clear: both;
  overflow: auto;

  @media (max-width: 700px) {
    @include padding($spacer * 4 0);
  }

  &__text {
    @include padding(0 0 0 $spacer * 7 + 2);
    position: relative;
    @media (min-width: 931px) {
      @include space(margin, left, $spacer * 4);
      margin-right: 8%;
    }
    @media (max-width: 700px) {
      font-size: $text-md;
      line-height: $line-height-md;
    }

    &::before {
      @include pos-absolute(-16px, 0, 0, 0);
      @include font(30px, $font-bold, normal, $lh: 40px);
      color: $color-primary-light-2;
      content: '❝';
      @media (min-width: 931px) {
        line-height: 50px;
      }
    }
  }

  &__source {
    @include space(margin, right, $spacer * 4);
    float: right;

    &::before {
      content: '-';
    }
    @media (min-width: 931px) {
      @include margins(0 8%);
    }
  }
}
