.fp-article-heading {
  &__catchline {
    display: block;
    color: $color-primary;
  }

  &__title {
    display: block;
  }
}
