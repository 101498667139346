.fp-latest-article-list {
  @include space(margin, bottom, $spacer * 6);
  display: flex;
  flex-direction: column;

  > a {
    display: flex;
    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }

  }

  .fp-article {
    &__image {
      max-width: 350px;
      min-width: 350px;
      width: 100%;

      @media screen and (max-width: $tablet) {
        max-width: 100%;
        min-width: initial;
      }
    }

    &__content {
      @include space(padding, all, $spacer * 4);

    }

    &__catchline {
      @include space(padding, top, 0);
      @media screen and (max-width: $mobile) {
        @include space(padding, bottom, 0);
      }
    }

    &__title {
      @include space(padding, top, $spacer * 2);
      color: $color-text;
    }
    // No main key visual
    &__no-img .fp-article {
      &__content {
        bottom: 0;
        float: initial;
        margin-bottom: 0;
        position: relative;
        @media screen and (max-width: $tablet) {
          @include space(margin, left, -16px);
          @include space(margin, right, -16px);
        }
        @media screen and (max-width: $mobile) {
          @include space(margin, left, -16px);
          @include space(margin, right, -16px);
          bottom: 0;
          margin-bottom: 0;
        }
      }
    }
  }
  // No main key visual
  .fp-article__no-img .fp-article {
    &__content {
      @include space(margin, left, 0);
      @include space(padding, all, $spacer * 6);
      bottom: 0;
      float: initial;
      margin-bottom: 0;
      position: relative;
      @media screen and (max-width: $tablet) {
        @include space(margin, left, -16px);
        @include space(margin, right, -16px);
      }
      @media screen and (max-width: $mobile) {
        @include space(margin, left, -16px);
        @include space(margin, right, -16px);
        bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}
// If article doesn't have image
.fp-article_teaser.fp-latest-article-list.fp-article__no-img {
  .fp-article__content {
    bottom: 0;
    margin: 0;
  }
}
