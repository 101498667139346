.fp-region-section__button {
  grid-column: span 3;



  @media screen and (max-width:$tablet) {
    grid-column: span 4;
  }
  @media screen and (max-width:750px) {
    grid-column: span 6;
  }

  a {
    @include space(padding, all, 16px);
    @include transition(0.2s, $expo);
    background-color: #e8ecf2;
    display: block;
    text-align: center;

    &:hover {
      background: darken(#e8ecf2, 5%);
    }

    @media screen and (max-width: $mobile) {
      font-size: $text-sm;
    }

    span {
      @include font($text-base-size, $weight-bold, normal, $font-secondary);
      @include elipsis(1);
      color: $color-primary;
      line-height: $line-height-base;
      white-space: nowrap;
    }
  }
}
