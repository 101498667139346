.fp-paywall_card-wrapper {
  @include space(padding, only-vertical, $spacer * 6);
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .fp-paywall_card {
    @include space(padding, all, $spacer * 2);
  }

  .fp-paywall_card:not(:last-child) {
    // @include space(margin, right, $spacer * 4);
  }

  .fp-paywall_card:not(:last-child) {
    // @include space(margin, right, $spacer * 4);
  }
  //.fp-paywall_card:not(:last-child) {
  //  @include space(margin, right, $spacer * 4);
  //}
}

.fp-paywall_card-container {
  @include space(padding, all, $spacer * 4 $spacer * 2);
  border: 1px solid $light-gray;
  border-radius: $spacer * 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fp-paywall_card {
  @include space(padding, all, $spacer * 2);
  display: flex;
  max-width: 300px;
  min-width: 240px;
  text-align: center;
  width: auto;


  &-title {
    @include space(padding, bottom, $spacer * 2);
    @include font($text-md, $weight-black, normal, $font-secondary);
    color: $color-primary;
    text-transform: uppercase;
    word-break: break-all;
  }

  &-description {
    @include space(padding, bottom, $spacer * 2);
    @include font($text-base-size, $weight-medium, normal, $font-secondary);
    color: $color-text--80;

  }

  &-button {
    @include space(margin, only-vertical, $spacer * 2);

    a {
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-image {
    img {
      max-height: 150px;
    }

    amp-img {
      max-height: 150px;
    }
  }
}


// Paywall Details
.fp-paywall_details {
  @include space(padding, bottom, $spacer * 2);
}
