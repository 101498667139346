//This Files Contains Css for the Article Block Header

.fp-article-heading {
  @include space(padding, only-vertical, $spacer * 8 $spacer * 6);
  @media (max-width: 700px) {
    @include space(padding, only-vertical, $spacer * 6 $spacer * 4);
  }

  &__catchline {
    display: block;
    color: $color-secondary;
    font-family: $font-secondary;
    text-transform: uppercase;
  }

  &__title {
    @include space(padding, only-vertical, $spacer * 2);
    display: block;

    @media (max-width: 700px) {
      font-size: $text-xl;
      line-height: $line-height-xl;
    }
  }

  &__excerpt {
    @include space(padding, only-vertical, $spacer * 2);
    @media (max-width: 700px) {
      font-size: $text-md;
      line-height: $line-height-md;
    }
  }

  &__details {
    @include space(padding, only-vertical, $spacer * 2);
    color: rgba(42, 40, 40, 0.5);
    display: flex;
    flex-wrap: wrap;

    .fp-article-heading {
      &__author {
        @include space(padding, bottom, $spacer * 2);

        @media (max-width: 700px) {
          font-size: $text-sm;
          line-height: $line-height-sm;
        }
      }

      &__date {
        @include space(padding, bottom, $spacer * 2);
        @media (max-width: 700px) {
          font-size: $text-sm;
          line-height: $line-height-sm;
        }
      }

      &__divider {
        @include space(padding, horizontal, $spacer * 2);
      }
    }

    .date-with-no-author {
      margin-left: -8px;
    }
  }

  &__details {
    display: flex;

    .fp-article-heading {
      &__author {
        @include space(padding, right, $spacer * 2);
      }
    }
  }
}
