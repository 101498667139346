@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE10+ CSS here

  .fp-top-article-teasers-wrapper {
    display: block;
  }

  .fp-top-article-top-teaser {
    display: block;
  }

  .fp-top-article-bottom-teasers.fp-grid-row {
    display: flex;
    grid-gap: 0;
    @media screen and (max-width: $mobile) {
      flex-direction: column;
    }

    > div {
      width: calc(50% - 8px);
      @media screen and (max-width: $mobile) {
        width: 100%;
      }

      &:first-child {
        margin-right: 16px;
        @media screen and (max-width: $mobile) {
          margin-bottom: 16px;
          margin-right: 0;
        }
      }
    }
  }

  .fp-mini-teasers .fp-grid-row {
    display: flex;
    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }

    > a {
      flex: 1;
    }
  }

}
