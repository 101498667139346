// This file contains the styles for the 60/40 CONTAINER
// It is imported in the _layout.scss file

.fp-col-1 {
  grid-column: span 1;
}

.fp-col-2 {
  grid-column: span 2;
}

.fp-col-3 {
  grid-column: span 3;
}

.fp-col-4 {
  grid-column: span 4;
}

.fp-col-5 {
  grid-column: span 5;
}

.fp-col-6 {
  grid-column: span 6;
}

.fp-col-7 {
  grid-column: span 7;
}

.fp-col-8 {
  grid-column: span 8;
}

.fp-col-9 {
  grid-column: span 9;
}

.fp-col-10 {
  grid-column: span 10;
}

.fp-col-11 {
  grid-column: span 11;
}

.fp-col-12 {
  grid-column: span 12;
}

.editor-fp-container,
main.fp-page-content {
  @include size($container-width, null, $maxw: $container-max-width);
  @include margins(0 auto);

  @media screen and (max-width: 1380px) {
    @include space(padding, horizontal, $spacer * 4);
  }
  @media screen and (max-width: $tablet) {
    @include size(auto, null, $maxw: $container-max-width);
    @include margins(0 auto);
  }
}


.fp-container__small {
  @include size(100%, null, $maxw: 600px);
  @include margins(0 auto);
  @media (max-width: 700px) {
    @include size(auto, null, $maxw: 600px);
  }
}

.fp-container__large {
  @include size(100%, null, $maxw: 820px);
  @include margins(0 auto);
  @media (max-width: 700px) {
    @include size(100%, null, $maxw: 820px);
  }
}

.fp-container__extra-large {
  @include size(100%, null, $maxw: $container-max-width);
  @include margins(0 auto);
  @media (max-width: 700px) {
    @include size(100%, null, $maxw: $container-max-width);
  }
}

.fp-page-content {
  background-color: white;
  flex: 1;
}
