@import '../default/variables';

// override any variable below

// Typography Variables
$font-primary: 'Domine', serif;
$font-secondary: 'Muli', sans-serif;

$publication-banner-logo-small-path: '/design/source/images/vs-plus-logo.png';
$color-primary: #00aeef;
$color-primary-darker: #0080af;

$publication-newsletter-logo: '/design/source/images/vs-logo.svg';







