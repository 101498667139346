.fp-table {
  border-collapse: collapse;
  display: table;
  min-height: 36px;
  width: 100%;
}

.fp-table_row {
  border-bottom: 1px solid #2e2e2e29;
  border-left: 1px solid #2e2e2e29;
  border-right: 1px solid #2e2e2e29;
  display: table-row;

  &:first-child {
    border-top: 1px solid #2e2e2e29;
  }
}

.fp-table_column {
  display: table-cell;
  flex: 1;
  min-width: 149px;
  padding: 8px;

  &:not(:last-child) {
    border-right: 1px solid #2e2e2e29;
  }
}

.fp-table_column-text {
  font-size: $text-base-size;
}

@media screen and (max-width: $mobile) {
  .fp-table_column-wrapper {
    flex-direction: column;
  }
}

.fp-table_row:last-child .fp-table_column {
  border-bottom: 0;
}

.fp-table-wrapper {
  margin-bottom: 24px;
  overflow: hidden;
  overflow-x: auto;
  padding: 16px 0;
  @media screen and (max-width: $mobile) {
    margin-bottom: 0;
  }
}

.fp-table_row:nth-child(odd) {
  background: #b1b1b114;
}
