// This file contains the styles for the layout GRID
// It is imported in the _layout.scss file
// Classes start with "fp" which stands for Forward Publishing

.fp-grid-row {
  @include size(100%, null);
  @include grid(repeat(12, 1fr), $spacer * 4, null);
}

.fp-page__header {
  display: inline-block;
  width: 100%;
}

.fp-page__body {
  @include padding(0 $body-margin);
  display: inline-block;
  width: 100%;
  @media (max-width: $tablet) {
    @include padding(0);
  }
}


.fp-page__body-margin {
  @include space(margin, left, $body-negative-margin);
  @include space(margin, right, $body-negative-margin);
  @media (max-width: 700px) {
    @include space(margin, left, 0);
    @include space(margin, right, 0);

    .fp-article__image {
      margin-left: calc(-100vw / 2 + (100vw - 32px) / 2);
      margin-right: calc(-100vw / 2 + (100vw - 32px) / 2);
      max-width: 100vw;
      width: 100vw;
    }
  }
}

.fp-section {
  @include space(margin, bottom,  64px);
}

// Add the class to the element that needs full container width
.full-width-element {
  @include space(margin, left, $body-negative-margin);
  @include space(margin, right, $body-negative-margin);
  max-width: 100vw;
  // body white space * 2
  width: calc(100% + 100px);

  @media screen and (max-width: $tablet) {
    // body white space * 2
    margin-left: calc(-100vw / 2 + (100vw - 32px) / 2);
    margin-right: calc(-100vw / 2 + (100vw - 32px) / 2);
    width: calc(100% + 32px);
  }
}

// Add the class to the article tag if you need to have image that is full container width wide
.full-width-image .fp-article__image {
  @extend .full-width-element;
}
