// This file contains all the TOP ARTICLE TEASERS styles
// This file is imported into the _groups.scss file

.fp-top-article-top-teaser {
  .fp-article__content {
    @media (max-width: 750px) {
      @include padding(0 0 8px 0);
    }

    .fp-article__title {
      font-size: $text-xxl;
      line-height: $line-height-xxl;
      @media (max-width: 750px) {
        font-size: $text-md;
        line-height: $line-height-md;
      }
    }
  }

  .fp-article__content {
    padding-top: 8px;
  }

  .fp-article__catchline {
    padding-top: 8px;
  }
}

.fp-top-article-bottom-teasers {
  &.fp-grid-row {
    grid-gap: $spacer * 6;
  }
  @media (max-width: 750px) {
    > div {
      grid-column: span 12;
    }
  }

  .fp-article__title {
    font-size: $text-md;
    line-height: $line-height-md;
  }

  .fp-article__content {
    padding-top: 8px;
  }

  .fp-article__catchline {
    padding-top: 8px;
  }
}
